import styled from 'styled-components';
import Image from 'gatsby-image';

export const SvgWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 60px;
`;

export const SliderWrapper = styled.div`
  width: 90%;
  height: auto;
  position: absolute;
  top: 160px;
  left: 20px;

  @media (min-width: 450px) {
    top: 200px;
  }

  @media (min-width: 550px) {
    top: 250px;
  }

  @media (min-width: 650px) {
    top: 290px;
  }

  @media (min-width: 780px) {
    top: 320px;
  }
`;

export const CatalogWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const CatalogWrapperImage = styled(Image)`
  width: 100%;
  height: auto;
`;

export const SquareWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 30px;
`;

export const TextBox = styled.p`
  width: 100%;
  height: auto;
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin: 30px 0;
  padding: 0 20px;
  text-align: center;
`;

export const TextWord = styled.span`
  display: inline-block;
`;
