import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PricingSvg from 'components/pricingSvg/pricingSvg';
import CircleRightSlow from 'components/circle/circleRightSlow';
import CircleLeft from 'components/circle/circleLeft';
import {
  BackgroundWrapper,
  SectionWrapper,
  CatalogWrapper,
  RatchetWrapper,
  ImageWrapper,
  FirstRatchetWrapper,
  SecondRatchetWrapper,
  ThirdRatchetWrapper,
} from './catalog.styled';

const Catalog = () => {
  const { background } = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "background.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <BackgroundWrapper Tag="div" fluid={background.sharp.fluid}>
      <SectionWrapper>
        <CatalogWrapper>
          <ImageWrapper>
            <PricingSvg />
          </ImageWrapper>
        </CatalogWrapper>
        <RatchetWrapper>
          <FirstRatchetWrapper>
            <CircleLeft />
          </FirstRatchetWrapper>
          <SecondRatchetWrapper>
            <CircleRightSlow />
          </SecondRatchetWrapper>
          <ThirdRatchetWrapper>
            <CircleRightSlow />
          </ThirdRatchetWrapper>
        </RatchetWrapper>
      </SectionWrapper>
    </BackgroundWrapper>
  );
};

export default Catalog;
