import styled from 'styled-components';

export const SectionWrapperDesktop = styled.div`
  display: none;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;

  @media (min-width: 850px) {
    display: flex;
  }
`;

export const SectionWrapperMobile = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  width: 100%;
  height: auto;
  position: relative;

  @media (min-width: 850px) {
    display: none;
  }
`;
