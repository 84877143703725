import React, { createRef, useEffect } from 'react';
import gsap from 'gsap';
import { useStaticQuery, graphql } from 'gatsby';
import { Svg, StyledLink } from './pricingsvg.styled';

const PricingSvg = () => {
  const { catalog } = useStaticQuery(graphql`
    query {
      catalog: file(relativePath: { eq: "fol_cennik.pdf" }) {
        publicURL
      }
    }
  `);

  const blueLineRef = createRef();

  const firstLineRef = createRef();
  const secondLineRef = createRef();
  const thirdLineRef = createRef();

  const bigCircleRef = createRef();
  const smallCircleRef = createRef();

  const firstContentRef = createRef();
  const secondContentRef = createRef();

  const blueDotRef = createRef();
  const whiteDotRef = createRef();

  const tl = gsap.timeline();

  useEffect(() => {
    tl.set(
      [
        blueLineRef.current,
        firstLineRef.current,
        secondLineRef.current,
        thirdLineRef.current,
        bigCircleRef.current,
        smallCircleRef.current,
        firstContentRef.current,
        secondContentRef.current,
        blueDotRef.current,
        whiteDotRef.current,
      ],
      { opacity: 0 }
    )
      .fromTo(
        blueDotRef.current,
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .fromTo(
        blueLineRef.current,
        0.25,
        { opacity: 1, scaleY: 0 },
        { scaleY: 1, ease: 'none' }
      )
      .fromTo(
        firstLineRef.current,
        0.45,
        { opacity: 1, scaleY: 0 },
        { scaleY: 1, ease: 'none' }
      )
      .fromTo(
        bigCircleRef.current,
        0.45,
        { opacity: 1, scale: 0, transformOrigin: '0% 0%' },
        { scale: 1, transformOrigin: '0% 0%' }
      )
      .set(firstContentRef.current, { opacity: 1 })
      .staggerFrom(firstContentRef.current.children, 0.45, { opacity: 0 }, 0.15)
      .fromTo(
        secondLineRef.current,
        0.45,
        { opacity: 1, scaleX: 0 },
        { scaleX: 1 }
      )
      .fromTo(
        whiteDotRef.current,
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .fromTo(
        thirdLineRef.current,
        0.45,
        { opacity: 1, scaleY: 0, transformOrigin: 'bottom' },
        { scaleY: 1, transformOrigin: 'bottom' },
        '-=0.25'
      )
      .fromTo(
        smallCircleRef.current,
        0.45,
        { opacity: 1, scale: 0, transformOrigin: 'bottom center' },
        { scale: 1, transformOrigin: 'bottom center' }
      )
      .set(secondContentRef.current, { opacity: 1 })
      .staggerFrom(
        secondContentRef.current.children,
        0.45,
        { opacity: 0 },
        0.15
      )
      .set(secondContentRef.current, {
        opacity: 0.3,
      });
  });

  return (
    <Svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 396.6 485.2"
      classNameName="st22"
    >
      <circle
        id="kolo"
        ref={blueDotRef}
        className="st0"
        cx="26.3"
        cy="5.8"
        r="5.8"
      />
      <line
        id="Lienia_niebieska"
        ref={blueLineRef}
        className="st1"
        x1="26.3"
        y1="2.8"
        x2="26.3"
        y2="54.8"
      />
      <StyledLink href={catalog.publicURL} download>
        <g id="kolo_1">
          <circle
            className="st2"
            ref={bigCircleRef}
            cx="105.1"
            cy="380.1"
            r="105.1"
          />
          <g ref={firstContentRef}>
            <g id="podloga">
              <g>
                <path
                  className="st0"
                  d="M163.5,432.1l-53.4-30.8c-1.8-1-4.6-1-6.4,0l-53,30.8c-1.7,1-1.7,2.7,0,3.7l53.4,30.8c1.8,1,4.6,1,6.4,0
				l53-30.8C165.3,434.8,165.3,433.1,163.5,432.1z"
                />
              </g>
              <g>
                <g className="st3">
                  <g>
                    <path
                      className="st4"
                      d="M163.5,420.7l-53.4-30.8c-1.8-1-4.6-1-6.4,0l-53,30.8c-1.7,1-1.7,2.7,0,3.7l53.4,30.8c1.8,1,4.6,1,6.4,0
						l53-30.8C165.3,423.4,165.3,421.7,163.5,420.7z"
                    />
                  </g>
                  <path
                    className="st5"
                    d="M163.6,424.4l-53,30.8c-1.7,1-4.6,1-6.4,0l-53.4-30.8c-0.9-0.5-1.3-1.2-1.3-1.9v4.2c0,0.7,0.4,1.3,1.3,1.9
					l53.4,30.8c1.8,1,4.6,1,6.4,0l53-30.8c0.9-0.5,1.3-1.2,1.3-1.8v-4.2C164.9,423.2,164.4,423.9,163.6,424.4z"
                  />
                </g>
              </g>
            </g>
            <path
              className="st6"
              d="M106.8,426.6c-2.5-1.3-5.1-2.5-7.8-3.2c0-0.2,0.1-0.5,0.1-0.7c0-3.8-6.4-6.8-14.4-6.8c-7.9,0-14.4,3-14.4,6.8
		s6.4,6.8,14.4,6.8h0.1c2.7,0,5.3,0.5,7.8,1.5c7.2,3.1,19.7,8.6,21,7c1.6-2,1.1-5.1-0.5-7.1C111.5,429,109.1,427.8,106.8,426.6z"
            />
            <g id="człowiek">
              <path
                className="st7"
                d="M123.5,429.6c-1.1-1.1-2.1-2.5-2.1-2.5l-3.3-0.5c0,0,0.1,2.5,0,3.4s0.4,2.1,0.7,2.3c0.3,0.2,2.9,1.1,5.8,2.3
			c3,1.1,3.7-0.2,3.9-0.6C128.8,433.7,124.6,430.7,123.5,429.6z"
              />
              <path
                className="st6"
                d="M121.5,427.2C121.5,427.2,121.4,427.1,121.5,427.2l-3.4-0.6c0,0,0.1,2.5,0,3.4s0.4,2.1,0.7,2.3
			c0.3,0.2,2.9,1.1,5.8,2.3c1,0.4,1.8,0.5,2.3,0.4C123.9,433.1,121.6,430.6,121.5,427.2z"
              />
              <g>
                <path
                  className="st8"
                  d="M118.7,394.2c-6.1-3.2-17.3-11.6-25.6-32.5c-2.3-1.3-5.2-1.2-7.4,0.2L74.2,369c-1.2,1.4-1.8,3.3-1.7,5.2
				c0.6,7.7,2.4,28,5.2,33.3c3.5,6.6,8.3,11.5,17.1,13c4.9,0.8,21.7-2.7,27.4-17.8l0.2-1.2C122.8,398.5,121.4,395.6,118.7,394.2z"
                />
                <path
                  className="st9"
                  d="M117.4,395.1c-13.5-7.7-21.3-23.1-24.2-29.6c-0.7-1.6-2.7-2.2-4.2-1.4l-10.8,6.2c-1.7,1-2.7,2.9-2.4,4.8
				c2.2,18.9,10.5,31.6,15.1,37.4c1.8,2.3,4.8,3.3,7.6,2.6c12.8-3.1,18.4-8.1,20.9-12.1C121.2,400.3,120.2,396.7,117.4,395.1z"
                />
              </g>
              <path
                className="st7"
                d="M122.2,432.7c-2.2-2.2-4-3.6-4-3.6l-1.7,2.9l0.1,1.3c0,0-0.1,1.3,0.9,2.1c0.7,0.6,3,1.6,4.6,2.6
			c3.1,2,6.3,0.4,5.9-0.9C127.7,435.8,124.4,434.9,122.2,432.7z"
              />
              <path
                className="st10"
                d="M115.3,402.4L99,392.1l-4.5,8.5l18,9.6l4.8,17.3c3.1,2.2,4.5-1.1,4.5-1.1l-2.6-19.2
			C118.9,405.1,117.1,403.3,115.3,402.4z"
              />
              <path
                className="st11"
                d="M116,413.5c-0.7-2.4-0.7-5.3-2.6-7.1c-1.5-1.5-3.7-2.3-5.7-2.9c-4.1-1.3-8.4-2-12.5-3.4
			c-0.2-0.1-0.3-0.1-0.4-0.2l-0.4,0.7l18,9.6l4.8,17.3c3.1,2.2,4.5-1.1,4.5-1.1l-0.4-2.8C119.1,420.6,117.1,417.2,116,413.5z"
              />
              <path
                className="st10"
                d="M107.8,407.2c-5.9-4.5-8-7.2-8-7.2v-8.4l-13.4,7.2l1.2,4.1c2.3,3.4,3.9,4.4,7.5,6.2l10.3,6.2l10.7,17.4
			c0,0,3.6,1.8,4.5-2.1l-7.3-17.3C113.4,413.2,111.2,409.7,107.8,407.2z"
              />
              <path
                className="st6"
                d="M94.2,403.4c-2.9-1.9-3.9-4.4-3.5-6.9l-4.1,2.2l1.2,4.1c2.3,3.4,3.9,4.4,7.5,6.2l10.3,6.2l10.7,17.4
			c0,0,3.1,1.5,4.2-1.3c-4.9-4.9-7.3-12.4-11.3-18.1C105.4,408,99.3,406.7,94.2,403.4z"
              />
              <path
                className="st12"
                d="M95,373.5c-1.2-2.3-3.2-3.2-5.6-2.7c-2.4,0.5-11.3,3.9-11.3,9.2c0,7,6.1,17.2,9.9,23.6c0,0,3.1,1.2,9-3.1
			c2.5-1.8,6-5.6,6-5.6s-3.2-4.1-4.2-8.3C97.3,380.4,95.4,374.2,95,373.5z"
              />
              <path
                className="st6"
                d="M90.9,389.6c-4.6-1.5-7.1-5.9-10.8-8.5c-0.6,0.3-1.2,0.7-1.8,1c1.1,6.8,6.3,15.6,9.7,21.4c0,0,3.1,1.2,9-3.1
			c2.5-1.8,6-5.6,6-5.6s-2.2-2.9-3.5-6.3C96.9,390.1,93.6,390.5,90.9,389.6z"
              />
              <g>
                <path
                  className="st13"
                  d="M83.6,374.1c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9v-4.8h-5.5L83.6,374.1z"
                />
                <path
                  className="st13"
                  d="M83,370.1c0,2.5,4.4,3.8,4.4,3.8c2.4,0,3.6-1.1,3.6-3.6V364l-8.5,1.5L83,370.1z"
                />
                <path
                  className="st14"
                  d="M89.9,361.5c-3.4-2.1-6.3-0.9-8.3,1.6c-1,1.2-0.3,3.7,0,4.9c0.7,2.7,2.2,4.1,2.2,4.1l0.3-4.2l0.5,0.8
				l0.7-0.5c0,0-0.3-1.8-0.3-2.1c0.1-1,6-1.2,6-1.2S93.6,363.8,89.9,361.5z"
                />
                <path
                  className="st13"
                  d="M82.6,368.1c-0.1,1.1,0.6,2.3,1.5,1.9v-2.1C84,367.9,82.8,366.7,82.6,368.1z"
                />
              </g>
              <path
                className="st12"
                d="M97.5,390.5l-11.1,1.6c0,0-1.8-7.1-2.3-9.7c-0.3-1.6-1.3-7.3-6-3.3c0,0,1.8,12.8,4.4,17.5
			c1.1,2.1,3.1,1.7,4,2.1l11.5-4.2C97.9,394.6,96.4,392.8,97.5,390.5z"
              />
              <path
                className="st12"
                d="M95,373.5l4,14.8c2.6,4.2,11.2,3.3,11.2,3.3l3.8-3.3c0,0-8.9-1.4-11-2.7C100.9,384.2,95,373.5,95,373.5z"
              />
              <path
                className="st13"
                d="M101.3,389.1l-4,1.9c-2.6,1.3,0,3.1,0,3.1s1.8,0.4,3.3,0s1.8-3.9,1.8-3.9l2.1-0.3c2-0.3,1.2-1.1,1.2-1.1
			L101.3,389.1z"
              />
              <g>
                <g className="st3">
                  <g>
                    <path
                      className="st15"
                      d="M121.2,378.5l-15.5,8.9c-0.3,0.2-0.6,0.5-0.7,0.7l-4.2,10.4c-0.1,0.2,0.1,0.3,0.3,0.1l15.5-8.9
						c0.3-0.2,0.6-0.5,0.7-0.7l4.2-10.4C121.6,378.4,121.5,378.3,121.2,378.5z"
                    />
                  </g>
                  <path
                    className="st16"
                    d="M120.6,377.9l-15.5,8.9c-0.3,0.2-0.6,0.5-0.7,0.7l-4.2,10.4c0,0.1,0,0.2,0,0.2l0.6,0.6c0,0,0-0.1,0-0.2
					l4.2-10.4c0.1-0.2,0.4-0.6,0.7-0.7l15.5-8.9c0.2-0.1,0.3-0.1,0.3-0.1l-0.6-0.6C120.9,377.8,120.8,377.8,120.6,377.9z"
                  />
                </g>
              </g>
              <path
                className="st2"
                d="M82.4,365.4c-1,0.3-1.6,1.6-1.2,3c0.4,1.4,1.5,2.3,2.5,2s1.6-1.6,1.2-3C84.5,366.1,83.4,365.2,82.4,365.4z"
              />
              <path
                className="st2"
                d="M85.3,360.3c-1,0.2-1.8,0.8-2.4,1.4c-0.7,0.7-0.9,2.2-0.9,3.2v1.3l1.6,0.4c0,0-0.2-2.5,0-3
			c0.4-1.5,1.4-2.5,2.6-2.8c1.8-0.4,2.5,0,2.5,0S87.4,359.8,85.3,360.3z"
              />
            </g>
            <g id="poloczenie">
              <g>
                <path
                  className="st0"
                  d="M114,378c-0.6,0-1,0.5-1,1v0.1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1C115,378.5,114.5,378,114,378z"
                />
              </g>
              <g>
                <path
                  className="st0"
                  d="M114,368.9c-0.6,0-1,0.5-1,1v0.1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1v-0.1C115,369.4,114.5,368.9,114,368.9z"
                />
                <path
                  className="st0"
                  d="M114,373.5c-0.6,0-1,0.5-1,1v0.1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1v-0.1C115,373.9,114.5,373.5,114,373.5z"
                />
                <path
                  className="st0"
                  d="M114,364.4c-0.6,0-1,0.5-1,1v0.1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1v-0.1C115,364.8,114.5,364.4,114,364.4z"
                />
                <path
                  className="st0"
                  d="M114,359.8c-0.6,0-1,0.5-1,1v0.1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1v-0.1C115,360.3,114.5,359.8,114,359.8z"
                />
              </g>
              <g>
                <path
                  className="st0"
                  d="M114.5,355.5c-0.5-0.3-1.1-0.1-1.4,0.4c-0.1,0.2-0.2,0.5-0.1,0.8c0.1,0.5,0.5,0.8,1,0.8c0.6,0,1-0.5,1-1
				C115,356,114.8,355.6,114.5,355.5z"
                />
              </g>
              <g>
                <path
                  className="st0"
                  d="M94.1,343.2c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4h0.1c0.2,0.1,0.3,0.1,0.5,0.1
				c0.3,0,0.7-0.2,0.9-0.5C94.7,344.2,94.5,343.5,94.1,343.2z"
                />
                <path
                  className="st0"
                  d="M90.7,341.2c-0.5-0.3-1.1-0.1-1.4,0.3c-0.3,0.5-0.1,1.1,0.3,1.4l0.1,0.1c0.2,0.1,0.3,0.1,0.5,0.1
				c0.3,0,0.7-0.2,0.9-0.5C91.3,342.1,91.1,341.5,90.7,341.2z"
                />
                <path
                  className="st0"
                  d="M87.3,339.1c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4h0.1c0.2,0.1,0.3,0.1,0.5,0.1
				c0.3,0,0.7-0.2,0.9-0.5C87.9,340.1,87.7,339.4,87.3,339.1z"
                />
                <path
                  className="st0"
                  d="M111.1,353.4c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4h0.1c0.2,0.1,0.3,0.1,0.5,0.1
				c0.3,0,0.7-0.2,0.9-0.5C111.7,354.4,111.5,353.7,111.1,353.4z"
                />
                <path
                  className="st0"
                  d="M83.9,337.1c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4H83c0.2,0.1,0.3,0.1,0.5,0.1
				c0.3,0,0.7-0.2,0.9-0.5C84.5,338,84.3,337.4,83.9,337.1z"
                />
                <path
                  className="st0"
                  d="M104.3,349.3c-0.5-0.3-1.1-0.1-1.4,0.3c-0.3,0.5-0.1,1.1,0.3,1.4l0.1,0.1c0.2,0.1,0.3,0.1,0.5,0.1
				c0.3,0,0.7-0.2,0.9-0.5C104.9,350.3,104.7,349.6,104.3,349.3z"
                />
                <path
                  className="st0"
                  d="M107.7,351.4c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4h0.1c0.2,0.1,0.3,0.1,0.5,0.1
				c0.3,0,0.7-0.2,0.9-0.5C108.3,352.3,108.1,351.7,107.7,351.4z"
                />
                <path
                  className="st0"
                  d="M100.9,347.3c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4h0.1c0.2,0.1,0.3,0.1,0.5,0.1
				c0.3,0,0.7-0.2,0.9-0.5C101.5,348.2,101.3,347.6,100.9,347.3z"
                />
                <path
                  className="st0"
                  d="M97.5,345.3c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.1,1.1,0.4,1.4h0.1c0.2,0.1,0.3,0.1,0.5,0.1
				c0.3,0,0.7-0.2,0.9-0.5C98.1,346.2,97.9,345.5,97.5,345.3z"
                />
              </g>
              <g>
                <path
                  className="st0"
                  d="M80,334.9c-0.6,0-1,0.5-1,1c0,0.4,0.2,0.7,0.5,0.9l0,0c0.2,0.1,0.3,0.1,0.5,0.1c0.3,0,0.7-0.2,0.9-0.5
				c0.1-0.2,0.2-0.5,0.1-0.8C80.9,335.3,80.5,334.9,80,334.9z"
                />
              </g>
              <g>
                <path
                  className="st0"
                  d="M80,326.9c-0.6,0-1,0.5-1,1v0.1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1.1C81,327.4,80.5,326.9,80,326.9z"
                />
                <path
                  className="st0"
                  d="M80,322.9c-0.6,0-1,0.5-1,1v0.1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1.1C81,323.4,80.5,322.9,80,322.9z"
                />
                <path
                  className="st0"
                  d="M80,319c-0.6,0-1,0.5-1,1v0.1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1.1S80.5,319,80,319z"
                />
                <path
                  className="st0"
                  d="M80,330.9c-0.6,0-1,0.5-1,1v0.1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1.1C81,331.4,80.5,330.9,80,330.9z"
                />
                <path
                  className="st0"
                  d="M80,307c-0.6,0-1,0.5-1,1v0.1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1.1C81,307.5,80.5,307,80,307z"
                />
                <path
                  className="st0"
                  d="M80,303c-0.6,0-1,0.5-1,1v0.1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1.1C81,303.5,80.5,303,80,303z"
                />
                <path
                  className="st0"
                  d="M80,311c-0.6,0-1,0.5-1,1v0.1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1.1S80.5,311,80,311z"
                />
                <path
                  className="st0"
                  d="M80,315c-0.6,0-1,0.5-1,1v0.1c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1.1S80.5,315,80,315z"
                />
              </g>
              <g>
                <path
                  className="st0"
                  d="M80.5,299.2c-0.3-0.2-0.7-0.2-1,0s-0.5,0.5-0.5,0.9v0.1c0,0.6,0.5,1,1,1h0.1c0.3,0,0.6-0.2,0.8-0.5
				C81.2,300.2,81,299.5,80.5,299.2z"
                />
              </g>
              <g>
                <path
                  className="st0"
                  d="M87,303.2L87,303.2c-0.6-0.3-1.1-0.2-1.4,0.3c-0.3,0.5-0.1,1.1,0.4,1.4c0.2,0.1,0.4,0.2,0.5,0.2
				c0.3,0,0.7-0.2,0.9-0.5C87.6,304.1,87.5,303.5,87,303.2z"
                />
                <path
                  className="st0"
                  d="M83.8,301.2L83.8,301.2c-0.6-0.3-1.1-0.2-1.4,0.3c-0.3,0.5-0.1,1.1,0.4,1.4c0.2,0.1,0.3,0.1,0.5,0.1
				c0.3,0,0.7-0.2,0.9-0.5C84.4,302.1,84.2,301.5,83.8,301.2z"
                />
                <path
                  className="st0"
                  d="M93.4,307.1L93.4,307.1c-0.6-0.3-1.1-0.2-1.4,0.3c-0.3,0.5-0.1,1.1,0.4,1.4c0.2,0.1,0.3,0.1,0.5,0.1
				c0.3,0,0.7-0.2,0.9-0.5C94,308,93.9,307.4,93.4,307.1z"
                />
                <path
                  className="st0"
                  d="M90.2,305.2L90.2,305.2c-0.6-0.3-1.1-0.2-1.4,0.3c-0.3,0.5-0.1,1.1,0.4,1.4c0.2,0.1,0.3,0.1,0.5,0.1
				c0.3,0,0.7-0.2,0.9-0.5C90.8,306.1,90.7,305.4,90.2,305.2z"
                />
              </g>
              <g>
                <path
                  className="st0"
                  d="M96.6,309.1L96.6,309.1c-0.5-0.3-1.1-0.2-1.4,0.3c-0.3,0.5-0.1,1.1,0.4,1.4c0.2,0.1,0.3,0.2,0.5,0.2
				c0.3,0,0.7-0.2,0.9-0.5C97.2,310,97,309.4,96.6,309.1z"
                />
              </g>
            </g>
            <g id="Chmura">
              <polygon
                className="st0"
                points="124.2,331.9 131.3,324.8 127.3,324.8 127.3,316.9 120.6,316.9 120.6,324.8 117,324.8 		"
              />
              <rect
                x="116.5"
                y="334.3"
                className="st0"
                width="15.3"
                height="2.9"
              />
              <path
                className="st0"
                d="M141.1,308.4c-0.3-4.7-4.2-8.4-9-8.4c-1.3,0-2.6,0.3-3.8,0.8c-2-2.5-5-3.9-8.2-3.9c-5.3,0-9.6,3.8-10.4,8.9
			c-4.2,0.4-7.6,3.9-7.6,8.3c0,3.8,2.7,7.2,6.3,8.1v0.2h9.7v-2.9h-8h-0.2c-2.8-0.2-5-2.6-5-5.4c0-3,2.5-5.5,5.5-5.5
			c0.2,0,0.3,0,0.5,0l1.5,0.1v-1.5c0.1-4.2,3.5-7.5,7.7-7.5c2.7,0,5.1,1.4,6.6,3.7l0.8,1.3l1.2-0.8c1-0.7,2.2-1,3.4-1
			c3.4,0,6.2,2.8,6.2,6.2c0,0.1,0,0.3,0,0.4l-0.1,1.3l1.3,0.2c2.1,0.3,3.8,2.1,3.8,4.3c0,2.4-1.9,4.3-4.3,4.3h-1.6h-7.2v2.9h8.8
			c0.1,0,0.1,0,0.2,0h1.1v-0.1c3.3-0.6,5.9-3.5,5.9-7C146.1,312,144.1,309.3,141.1,308.4z"
              />
            </g>
          </g>
        </g>
      </StyledLink>
      <circle
        id="kolo_2_2_"
        ref={whiteDotRef}
        className="st2"
        cx="320.6"
        cy="382.6"
        r="8.6"
      />
      <StyledLink href={catalog.publicURL} download>
        <g id="kolo_3">
          <circle
            id="kolo_2"
            className="st2"
            cx="321.6"
            cy="205.3"
            r="75.8"
            ref={smallCircleRef}
          />
          <g ref={secondContentRef} className="st23">
            <g id="Chmura_1_">
              <polygon
                className="st0"
                points="321.6,184.6 328.7,177.5 324.8,177.5 324.8,169.6 318,169.6 318,177.5 314.5,177.5 		"
              />
              <rect
                x="313.9"
                y="187.1"
                className="st0"
                width="15.3"
                height="2.9"
              />
              <path
                className="st0"
                d="M338.6,161.1c-0.3-4.7-4.2-8.4-9-8.4c-1.3,0-2.6,0.3-3.8,0.8c-2-2.5-5-3.9-8.2-3.9c-5.3,0-9.6,3.8-10.4,8.9
			c-4.2,0.4-7.6,3.9-7.6,8.3c0,3.8,2.7,7.2,6.3,8.1v0.2h9.7v-2.8h-8h-0.2c-2.8-0.2-5-2.6-5-5.4c0-3,2.5-5.5,5.5-5.5
			c0.2,0,0.3,0,0.5,0l1.5,0.1V160c0.1-4.2,3.5-7.5,7.7-7.5c2.7,0,5.1,1.4,6.6,3.7l0.8,1.3l1.2-0.8c1-0.7,2.2-1,3.4-1
			c3.4,0,6.2,2.8,6.2,6.2c0,0.1,0,0.3,0,0.4l-0.1,1.3l1.3,0.2c2.1,0.3,3.8,2.1,3.8,4.3c0,2.4-1.9,4.3-4.3,4.3h-1.6h-7.2v2.9h8.8
			c0.1,0,0.1,0,0.2,0h1.1v-0.1c3.3-0.6,5.9-3.5,5.9-7C343.6,164.8,341.5,162.1,338.6,161.1z"
              />
            </g>
            <rect
              x="217.2"
              y="206.5"
              className="st18"
              width="210.3"
              height="54.6"
            />
            <text
              transform="matrix(1 0 0 1 298.5998 222.2)"
              className="st19 st20 st21"
            >
              Pobierz{' '}
            </text>
            <text
              transform="matrix(1 0 0 1 281.3998 245.4)"
              className="st19 st20 st21"
            >
              cennik PLN
            </text>
          </g>
        </g>
      </StyledLink>
      <line
        id="linia_biala_1"
        ref={firstLineRef}
        className="st17"
        x1="26.6"
        y1="54.8"
        x2="26.6"
        y2="315.3"
      />
      <line
        id="linia_biala_2"
        ref={secondLineRef}
        className="st17"
        x1="210.2"
        y1="382.6"
        x2="320.6"
        y2="382.6"
      />
      <line
        id="linia_biala_3"
        ref={thirdLineRef}
        className="st17"
        x1="320.6"
        y1="384"
        x2="320.6"
        y2="281.2"
      />
    </Svg>
  );
};

export default PricingSvg;
