import React, { createRef, useEffect } from 'react';
import gsap from 'gsap';
import { Svg } from './factorySvgMobile.styled';

const FactorySvgMobile = () => {
  const firstRatchetRef = createRef();
  const secondRatchetRef = createRef();
  const thirdRatchetRef = createRef();
  const fourthRatchetRef = createRef();

  const firstDotRef = createRef();
  const secondDotRef = createRef();
  const thirdDotRef = createRef();
  const fourthDotRef = createRef();

  const firstRatchetRotateTl = gsap.timeline({ repeat: -1 });
  const secondRatchetRotateTl = gsap.timeline({ repeat: -1 });
  const thirdRatchetRotateTl = gsap.timeline({ repeat: -1 });
  const fourthRatchetRotateTl = gsap.timeline({ repeat: -1 });

  const firstDotTl = gsap.timeline({ repeat: -1 });
  const secondDotTl = gsap.timeline({ repeat: -1 });
  const thirdDotTl = gsap.timeline({ repeat: -1 });
  const fourthDotTl = gsap.timeline({ repeat: -1 });

  useEffect(() => {
    firstRatchetRotateTl.to(firstRatchetRef.current, 20, {
      rotation: 360,
      transformOrigin: 'center center',
      ease: 'none',
    });
    secondRatchetRotateTl.to(secondRatchetRef.current, 10, {
      rotation: 360,
      transformOrigin: 'center center',
      ease: 'none',
    });
    thirdRatchetRotateTl.to(thirdRatchetRef.current, 10, {
      rotation: 360,
      transformOrigin: 'center center',
      ease: 'none',
    });
    fourthRatchetRotateTl.to(fourthRatchetRef.current, 60, {
      rotation: 360,
      transformOrigin: 'center center',
      ease: 'none',
    });
    firstDotTl
      .set(firstDotRef.current, {
        y: 5,
        scale: 1,
        transformOrigin: 'center center',
      })
      .to(firstDotRef.current, 8, {
        x: 20,
        y: -35,
        scale: 2,
        transformOrigin: 'center center',
        opacity: 0.7,
        ease: 'none',
      })
      .to(firstDotRef.current, 8, {
        y: -80,
        scale: 3,
        transformOrigin: 'center center',
        opacity: 0,
        ease: 'none',
      });
    secondDotTl
      .set(secondDotRef.current, {
        y: 5,
        scale: 1,
        transformOrigin: 'center center',
      })
      .to(secondDotRef.current, 15, {
        x: -10,
        y: -50,
        scale: 3.5,
        transformOrigin: 'center center',
        opacity: 0,
        ease: 'none',
      });
    thirdDotTl
      .set(thirdDotRef.current, {
        y: 5,
        scale: 1,
        transformOrigin: 'center center',
      })
      .to(thirdDotRef.current, 10, {
        x: -20,
        y: -70,
        scale: 3,
        transformOrigin: 'center center',
        opacity: 0,
        ease: 'none',
      });
    fourthDotTl
      .set(fourthDotRef.current, {
        y: 5,
        scale: 1,
        transformOrigin: 'center center',
      })
      .to(fourthDotRef.current, 12, {
        x: 20,
        y: -90,
        scale: 4,
        transformOrigin: 'center center',
        opacity: 0,
        ease: 'none',
      });
  });

  return (
    <Svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 796.7 756.2"
      className="st4"
    >
      <circle
        id="Dym_1"
        ref={firstDotRef}
        className="st0"
        cx="470.1"
        cy="200.4"
        r="7.8"
      />
      <circle
        id="Dym_2"
        ref={secondDotRef}
        className="st0"
        cx="556"
        cy="182.7"
        r="5.5"
      />
      <circle
        id="Dym_3"
        ref={thirdDotRef}
        className="st0"
        cx="645.5"
        cy="213.9"
        r="7.8"
      />
      <circle
        id="Dym_4"
        ref={fourthDotRef}
        className="st0"
        cx="747.8"
        cy="193.2"
        r="5.5"
      />
      <rect
        id="SZESCIAN"
        y="224.1"
        className="st1"
        width="796.7"
        height="532.1"
      />
      <path
        id="komin_1"
        className="st1"
        d="M483.1,197.2h-24.4c-7.7,0-13.9,6.2-13.9,13.9v34.7c0,7.7,6.2,13.9,13.9,13.9h24.4
	c7.7,0,13.9-6.2,13.9-13.9v-34.7C497,203.4,490.8,197.2,483.1,197.2z"
      />
      <path
        id="komin_2"
        className="st1"
        d="M580.1,182.7h-46c-7.7,0-13.9,6.2-13.9,13.9v46c0,7.7,6.2,13.9,13.9,13.9h46
	c7.7,0,13.9-6.2,13.9-13.9v-46C594,188.9,587.8,182.7,580.1,182.7z"
      />
      <path
        id="komin_3"
        className="st1"
        d="M668.6,210.7h-46c-7.7,0-13.9,6.2-13.9,13.9v52.2c0,7.7,6.2,13.9,13.9,13.9h46
	c7.7,0,13.9-6.2,13.9-13.9v-52.2C682.5,216.9,676.3,210.7,668.6,210.7z"
      />
      <path
        id="komin_4"
        className="st1"
        d="M771,193.2h-44.1c-8.2,0-14.8,6.6-14.8,14.8v82.2c0,8.2,6.6,14.8,14.8,14.8H771
	c8.2,0,14.8-6.6,14.8-14.8V208C785.8,199.8,779.2,193.2,771,193.2z"
      />
      <g id="Trybik_1" ref={firstRatchetRef}>
        <path
          className="st1"
          d="M83.7,277.7c-17.7,6.5-37.3-2.7-43.8-20.4s2.7-37.3,20.4-43.8s37.3,2.7,43.8,20.4c1.4,3.9,2.1,7.8,2.1,11.7
		l0,0C106.2,259.6,97.6,272.7,83.7,277.7 M27,244.4c-0.1,4,0.3,8,1.3,11.9l-3.6,1.3l3.1,8.6l3.6-1.3c1.9,3.8,4.2,7.3,7,10.4
		l-2.2,2.4l6.8,6.2l2.2-2.5c2.3,1.6,4.7,3.1,7.3,4.3c3.1,1.4,6.2,2.5,9.5,3.2l-0.4,3.5l9.1,1l0.4-3.5c3.9,0,7.8-0.5,11.7-1.5
		l1.5,4.3l8.6-3.1l-1.6-4.5c3.7-1.8,7-4.2,10-6.9l3.2,2.9l6.2-6.7l-3.3-3.1c1.6-2.2,3-4.6,4.1-7.1c0.4-0.9,0.8-1.9,1.2-2.9l4.3,1.1
		l2.2-8.9l-4.1-1c0.6-3.8,0.7-7.7,0.3-11.5l4.9-1l-1.7-7.8l-0.4-1.1l-4.7,1c-0.2-0.6-0.4-1.2-0.6-1.7c-1.2-3.3-2.7-6.4-4.6-9.2
		l3.2-2.6l-5.9-7l-3.1,2.6c-2.9-2.8-6.1-5.3-9.7-7.3l1.7-4l-8.5-3.5l-1.7,4c-2.7-0.9-5.5-1.4-8.4-1.8l-0.1-3.1l-9.2,0.3l0.1,2.7
		c-3.7,0.4-7.3,1.2-10.9,2.5c-0.3,0.1-0.7,0.3-1,0.4l-1.6-3l0,0l-8.1,4.2l1.5,2.9c-3.6,2.4-6.8,5.2-9.6,8.5l-2.2-1.5l-5.2,7.5
		l2.3,1.6c-0.4,0.7-0.8,1.5-1.1,2.3c-1.3,2.8-2.3,5.6-3,8.5l-3.4-0.3l-0.9,9.1L27,244.4z"
        />
        <path
          className="st1"
          d="M70.3,239.9l-2,2.3l0,0l-1.4,1.7l0.9,2.4l0,0l0,0l0.9,2.6l1.5,0.3l0,0l3.7,0.7l0.1-0.2l0,0l2.2-2.6l0,0
		l1.1-1.2l-0.7-2l0,0l-1.1-3l-1.1-0.2l0,0l-4-0.7L70.3,239.9z M73.1,242.5l0.7,0.4l-0.2,0.3c0.2,0.2,0.4,0.5,0.5,0.7l0.4-0.1
		l0.2,0.8l-0.4,0.1c0,0.3,0,0.6-0.1,0.9l0.4,0.2l-0.4,0.7l-0.3-0.2c-0.2,0.2-0.5,0.4-0.7,0.5l0.1,0.4l-0.8,0.2l-0.1-0.4
		c-0.3,0-0.6,0-0.9-0.1l-0.2,0.3l-0.7-0.4l0.2-0.3c-0.2-0.2-0.4-0.5-0.6-0.8l-0.4,0.1l-0.2-0.8l0.4-0.1c0-0.3,0-0.6,0.2-0.9
		l-0.3-0.2l0.4-0.7l0.3,0.2c0.2-0.2,0.5-0.4,0.8-0.5l-0.1-0.4l0.8-0.2l0.1,0.4c0.3,0,0.6,0.1,0.9,0.2L73.1,242.5z"
        />
      </g>
      <g id="Trybik_2" ref={secondRatchetRef}>
        <path
          className="st1"
          d="M125.6,204.4c-5.7,2.1-12.1-0.9-14.2-6.6s0.9-12.1,6.6-14.2c5.7-2.1,12.1,0.9,14.2,6.6
		c0.5,1.3,0.7,2.5,0.7,3.8l0,0C132.9,198.5,130.1,202.8,125.6,204.4 M107.2,193.6c0,1.3,0.1,2.6,0.4,3.9l-1.2,0.4l1,2.8l1.2-0.4
		c0.6,1.2,1.4,2.4,2.3,3.4l-0.7,0.8l2.2,2l0.7-0.8c0.7,0.5,1.5,1,2.4,1.4c1,0.5,2,0.8,3.1,1l-0.1,1.1l3,0.3l0.1-1.1
		c1.3,0,2.5-0.2,3.8-0.5l0.5,1.4l2.8-1l-0.5-1.5c1.2-0.6,2.3-1.3,3.2-2.2l1,0.9l2-2.2l-1.1-1c0.5-0.7,1-1.5,1.3-2.3
		c0.1-0.3,0.3-0.6,0.4-0.9l1.4,0.3l0.7-2.9l-1.3-0.3c0.2-1.2,0.2-2.5,0.1-3.7l1.6-0.3l-0.5-2.5l-0.1-0.4l-1.5,0.3
		c-0.1-0.2-0.1-0.4-0.2-0.6c-0.4-1.1-0.9-2.1-1.5-3l1-0.9l-1.9-2.3l-1,0.8c-0.9-0.9-2-1.7-3.1-2.4l0.5-1.3l-2.7-1.2L126,180
		c-0.9-0.3-1.8-0.5-2.7-0.6v-1l-3,0.1v0.9c-1.2,0.1-2.4,0.4-3.5,0.8c-0.1,0-0.2,0.1-0.3,0.1l-0.5-1l0,0l-2.6,1.4l0.5,1
		c-1.2,0.8-2.2,1.7-3.1,2.8l-0.7-0.5l-1.7,2.4l0.7,0.5c-0.1,0.2-0.3,0.5-0.4,0.7c-0.4,0.9-0.7,1.8-1,2.8l-1.1-0.1l-0.3,3
		L107.2,193.6z"
        />
        <path
          className="st1"
          d="M121.3,192.1l-0.6,0.8l0,0l-0.5,0.5l0.3,0.8l0,0l0,0l0.3,0.8l0.5,0.1l0,0l1.2,0.2v-0.1l0,0l0.7-0.9l0,0
		l0.3-0.4l-0.2-0.7l0,0l-0.3-1l-0.4-0.1l0,0L121.3,192.1L121.3,192.1z M122.2,193l0.2,0.1l-0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2h0.1
		l0.1,0.3h-0.1c0,0.1,0,0.2,0,0.3l0.1,0.1l-0.1,0.2l-0.1-0.1c-0.1,0.1-0.1,0.1-0.2,0.2v0.1l-0.3,0.1v-0.1c-0.1,0-0.2,0-0.3,0
		l-0.1,0.1l-0.2-0.1l0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3h-0.1l-0.1-0.3h0.1c0-0.1,0-0.2,0.1-0.3l-0.1-0.1l0.1-0.2l0.1,0.1
		c0.1-0.1,0.2-0.1,0.3-0.2V193l0.3-0.1v0.1c0.1,0,0.2,0,0.3,0.1L122.2,193z"
        />
      </g>
      <g id="Trybik_3" ref={thirdRatchetRef}>
        <path
          className="st1"
          d="M158.6,248.1c-9,3.3-18.9-1.4-22.1-10.3c-3.3-9,1.4-18.9,10.3-22.1c9-3.3,18.9,1.4,22.1,10.3
		c0.7,2,1,3.9,1,5.9l0,0C170,238.9,165.6,245.6,158.6,248.1 M130,231.3c-0.1,2,0.2,4,0.7,6l-1.8,0.7l1.6,4.4l1.8-0.7
		c0.9,1.9,2.1,3.7,3.6,5.3l-1.1,1.2l3.4,3.1l1.1-1.2c1.1,0.8,2.4,1.6,3.7,2.2c1.6,0.7,3.2,1.3,4.8,1.6l-0.2,1.8l4.6,0.5l0.2-1.8
		c2,0,4-0.3,5.9-0.8l0.8,2.2l4.4-1.6l-0.8-2.3c1.8-0.9,3.5-2.1,5-3.5l1.6,1.5l3.1-3.4l-1.7-1.5c0.8-1.1,1.5-2.3,2.1-3.6
		c0.2-0.5,0.4-1,0.6-1.5l2.2,0.5l1.1-4.5l-2.1-0.5c0.3-1.9,0.4-3.9,0.2-5.8l2.5-0.5l-0.8-4l-0.2-0.5l-2.4,0.5
		c-0.1-0.3-0.2-0.6-0.3-0.9c-0.6-1.7-1.4-3.2-2.3-4.7l1.6-1.3l-3-3.6l-1.6,1.3c-1.4-1.4-3.1-2.7-4.9-3.7l0.9-2l-4.3-1.8l-0.8,2
		c-1.4-0.4-2.8-0.7-4.2-0.9V208l-4.6,0.1v1.4c-1.9,0.2-3.7,0.6-5.5,1.3c-0.2,0.1-0.3,0.1-0.5,0.2l-0.8-1.5l0,0l-4.1,2.1l0.8,1.5
		c-1.8,1.2-3.5,2.6-4.9,4.3l-1.1-0.8l-2.6,3.8l1.2,0.8c-0.2,0.4-0.4,0.8-0.6,1.1c-0.7,1.4-1.2,2.8-1.5,4.3l-1.7-0.2l-0.5,4.6
		L130,231.3z"
        />
        <path
          className="st1"
          d="M151.8,229l-1,1.2l0,0l-0.7,0.8l0.4,1.2l0,0l0,0l0.5,1.3l0.7,0.1l0,0l1.9,0.3l0.1-0.1l0,0l1.1-1.3l0,0l0.5-0.6
		l-0.4-1l0,0l-0.5-1.5l-0.6-0.1l0,0L151.8,229L151.8,229z M153.2,230.3l0.4,0.2l-0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.4h0.2l0.1,0.4h-0.2
		c0,0.2,0,0.3-0.1,0.5l0.2,0.1l-0.2,0.4l-0.2-0.1c-0.1,0.1-0.2,0.2-0.4,0.3v0.2l-0.4,0.1v-0.2c-0.2,0-0.3,0-0.5-0.1l-0.1,0.2
		l-0.4-0.2l0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.4h-0.2l-0.1-0.4h0.2c0-0.2,0-0.3,0.1-0.5l-0.2-0.1l0.2-0.4l0.2,0.1
		c0.1-0.1,0.2-0.2,0.4-0.3v-0.2l0.4-0.1v0.2c0.2,0,0.3,0,0.5,0.1L153.2,230.3z"
        />
      </g>
      <g id="Trybik_4" ref={fourthRatchetRef}>
        <path
          className="st1"
          d="M297.6,287.9c-27.2,9.9-57.3-4.1-67.2-31.3s4.1-57.3,31.3-67.2c27.2-9.9,57.3,4.1,67.2,31.3
		c2.2,5.9,3.2,12,3.2,17.9l0,0C332.1,260,318.9,280.1,297.6,287.9 M210.6,236.8c-0.2,6.1,0.5,12.3,2.1,18.3l-5.5,2l4.8,13.2l5.5-2
		c2.8,5.9,6.5,11.2,10.8,16L225,288l10.4,9.5l3.4-3.8c3.5,2.5,7.2,4.7,11.2,6.6c4.7,2.2,9.6,3.8,14.5,4.9l-0.6,5.4l14,1.5l0.6-5.4
		c6,0,12-0.8,17.9-2.4l2.3,6.6l13.2-4.7l-2.5-6.9c5.6-2.8,10.7-6.4,15.3-10.6l4.9,4.5l9.5-10.3l-5.1-4.7c2.4-3.4,4.5-7,6.3-10.9
		c0.7-1.5,1.3-2.9,1.9-4.4l6.7,1.6l3.4-13.7l-6.4-1.6c0.9-5.8,1.1-11.8,0.5-17.7l7.5-1.6l-2.6-12l-0.6-1.7l-7.2,1.5
		c-0.3-0.9-0.6-1.8-0.9-2.6c-1.8-5-4.2-9.8-7.1-14.1l4.9-4.1l-9-10.8l-4.8,4c-4.4-4.3-9.4-8.1-14.8-11.1l2.6-6.2l-13-5.4l-2.5,6.1
		c-4.2-1.3-8.5-2.2-12.8-2.7l-0.1-4.7l-14.1,0.4l0.1,4.2c-5.6,0.6-11.2,1.8-16.7,3.8c-0.5,0.2-1,0.4-1.6,0.6l-2.4-4.6l0,0l-12.5,6.5
		l2.4,4.5c-5.5,3.6-10.5,8-14.7,13.1l-3.4-2.3l-8,11.6l3.5,2.4c-0.6,1.1-1.2,2.3-1.7,3.5c-2,4.3-3.5,8.6-4.6,13.1l-5.2-0.5l-1.4,14
		L210.6,236.8z"
        />
        <path
          className="st1"
          d="M276.9,229.9l-3.1,3.6l0,0l-2.2,2.6l1.3,3.7c0,0,0,0,0.1-0.1c0,0,0,0-0.1,0.1l1.4,4l2.3,0.4l0,0l5.8,1.1
		l0.2-0.3l0,0l3.4-4l0,0l1.6-1.9l-1.1-3.1l0,0l-1.6-4.5l-1.7-0.3l0,0l-6.1-1.1L276.9,229.9z M281.3,233.9l1.1,0.7l-0.3,0.5
		c0.3,0.3,0.6,0.7,0.8,1.1l0.6-0.1l0.3,1.2l-0.6,0.1c0,0.5-0.1,1-0.2,1.4l0.5,0.3l-0.7,1.1l-0.5-0.3c-0.3,0.3-0.7,0.6-1.1,0.8
		l0.1,0.6l-1.2,0.3L280,241c-0.5,0-1,0-1.4-0.2l-0.3,0.5l-1.1-0.7l0.3-0.5c-0.4-0.3-0.7-0.7-0.9-1.2L276,239l-0.3-1.2l0.6-0.1
		c0-0.5,0.1-1,0.3-1.4l-0.5-0.3l0.7-1.1l0.5,0.3c0.3-0.4,0.7-0.6,1.2-0.8l-0.1-0.6l1.2-0.3l0.1,0.6c0.5,0,1,0.1,1.4,0.3L281.3,233.9
		z"
        />
      </g>
    </Svg>
  );
};

export default FactorySvgMobile;
