import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import Image from 'gatsby-image';

export const BackgroundWrapper = styled(BackgroundImage)`
  background-size: cover;
  width: 100%;
  height: initial;

  @media (min-width: 850px) {
    height: 50%;
    display: flex;
    justify-content: flex-start;
  }

  @media (min-width: 1100px) {
    @media (min-height: 600px) {
      height: 435px !important;
    }
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  flex-flow: column;

  @media (min-width: 850px) {
    flex-flow: row;
    width: calc(100% - 50px);
    margin-left: 50px;
    height: 100%;
  }
`;

export const CatalogWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 850px) {
    width: 50%;
  }
`;

export const RatchetWrapper = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (min-width: 850px) {
    width: 50%;
    height: 100%;
    overflow: visible;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 220px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 850px) {
    height: 50%;
  }
`;

export const FirstRatchetWrapper = styled.div`
  width: 100px;
  height: auto;
  position: absolute;
  top: -0px;
  left: 10px;

  @media (min-width: 850px) {
    display: none;
  }

  @media (min-width: 970px) {
    display: block;
  }

  @media (min-width: 850px) {
    top: 80px;
    left: 70px;
  }

  @media (min-width: 1100px) {
    left: 180px;
  }

  @media (min-width: 1300px) {
    left: 280px;
  }

  @media (min-width: 1350px) {
    left: 130px;
  }

  @media (min-width: 1400px) {
    left: 160px;
    top: 70px;
  }

  @media (min-width: 1550px) {
    left: 200px;
  }

  @media (min-width: 1700px) {
    left: 180px;
    top: 70px;
  }
`;

export const SecondRatchetWrapper = styled.div`
  width: 400px;
  height: auto;
  position: absolute;
  top: 110px;
  left: -120px;

  @media (min-width: 850px) {
    display: none;
  }

  @media (min-width: 970px) {
    display: block;
  }

  @media (min-width: 850px) {
    width: 300px;
    top: 200px;
    left: -50px;
  }

  @media (min-width: 1100px) {
    left: 50px;
  }

  @media (min-width: 1300px) {
    left: 120px;
  }

  @media (min-width: 1350px) {
    left: -10px;
  }

  @media (min-width: 1400px) {
    width: 400px;
    left: 100px;
    top: 330px;
  }

  @media (min-width: 1550px) {
    left: -40px;
    top: 220px;
  }

  @media (min-width: 1700px) {
    left: 100px;
    top: 200px;
  }
`;

export const ThirdRatchetWrapper = styled.div`
  width: 250px;
  height: auto;
  position: absolute;
  top: 50px;
  right: -120px;

  @media (min-width: 1400px) {
    top: 70px;
    right: -100px;
  }
`;

export const TextBox = styled.p`
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 180px;
  left: 100px;

  @media (min-width: 850px) {
    top: 310px;
    left: 130px;
  }
`;

export const TextWord = styled.span`
  display: inline-block;
`;

export const TextImage = styled(Image)`
  width: 12px;
  height: 12px;
  position: absolute;
  top: 2px;
`;

export const LinkWrapper = styled.a`
  text-decoration: none;
  cursor: pointer;
`;
