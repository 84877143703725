import styled from 'styled-components';

export const Svg = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  top: -25px;

  @media (min-width: 850px) {
    width: 350px;
    height: auto;
    top: -157px;
    left: 50px;
  }

  .st0 {
    fill: #8fc1d4;
  }
  .st1 {
    fill: none;
    stroke: #8fc1d4;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }
  .st2 {
    fill: #ffffff;
  }
  .st3 {
    enable-background: new;
  }
  .st4 {
    fill: #efffff;
  }
  .st5 {
    fill: #706f6f;
  }
  .st6 {
    opacity: 0.1;
    fill: #243551;
    enable-background: new;
  }
  .st7 {
    fill: #0d6189;
  }
  .st8 {
    fill: #ff8484;
  }
  .st9 {
    fill: #d36e6e;
  }
  .st10 {
    fill: #243551;
  }
  .st11 {
    opacity: 0.2;
    fill: #243551;
    enable-background: new;
  }
  .st12 {
    fill: #0081a7;
  }
  .st13 {
    fill: #f7b7a9;
  }
  .st14 {
    fill: #81524f;
  }
  .st15 {
    fill: #c1deea;
  }
  .st16 {
    fill: #b7d1d8;
  }
  .st17 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }
  .st18 {
    fill: none;
  }
  .st19 {
    fill: #7c7c7c;
  }
  .st21 {
    font-size: 16px;
  }
  .st22 {
    enable-background: new 0 0 396.6 485.2;
  }
`;

export const StyledLink = styled.a`
  text-decoration: none;
  transition: opacity 0.3 ease-in-out;
  cursor: pointer;

  :hover .st23 {
    opacity: 1 !important;
  }
`;
