import styled from 'styled-components';

export const Svg = styled.svg`
  width: 100%;
  height: auto;

  .st0 {
    fill: #932a85;
  }
  .st1 {
    fill: #8fc1d4;
  }
  .st2 {
    fill: #ffffff;
  }
  .st3 {
    fill: #efeae9;
  }
  .st4 {
    enable-background: new 0 0 796.7 756.2;
  }
`;
