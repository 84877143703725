import React, { useEffect, createRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import gsap from 'gsap';
import { ScrollScene } from 'scrollscene';
import SquareAnimationNoMargin from 'components/squareAnimationNoMargin/squareAnimationNoMargin';
import PricingSlider from 'components/pricingSlider/pricingSlider';
import FactorySvg from 'components/factorySvg/factorySvg';
import {
  CatalogWrapper,
  TextWrapper,
  SquareWrapper,
  CatalogImageWrapper,
  SvgWrapper,
  SliderWrapper,
  CatalogImage,
  TextBox,
  TextWord,
} from './informationDesktop.styled';

const Information = () => {
  const { catalog } = useStaticQuery(graphql`
    query {
      catalog: file(relativePath: { eq: "katalog.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const titleRef = createRef();

  const titleTl = gsap.timeline({ paused: true });

  useEffect(() => {
    titleTl.staggerFrom(
      titleRef.current.children,
      0.45,
      { y: -50, opacity: 0 },
      0.015
    );

    const scrollscene = new ScrollScene({
      triggerElement: titleRef.current,
      reverse: false,
      gsap: {
        timeline: titleTl,
      },
    });
  });

  return (
    <>
      <CatalogWrapper>
        <SvgWrapper>
          <FactorySvg />
        </SvgWrapper>
        <SliderWrapper>
          <PricingSlider />
        </SliderWrapper>
      </CatalogWrapper>
      <TextWrapper>
        <SquareWrapper>
          <SquareAnimationNoMargin />
        </SquareWrapper>
        <TextBox ref={titleRef}>
          <TextWord>Pobierz&nbsp;</TextWord>
          <TextWord>wersję&nbsp;</TextWord>
          <TextWord>na&nbsp;</TextWord>
          <TextWord>swój&nbsp;</TextWord>
          <TextWord>komputer:</TextWord>
        </TextBox>
        <CatalogImageWrapper>
          <CatalogImage fluid={catalog.sharp.fluid} />
        </CatalogImageWrapper>
      </TextWrapper>
    </>
  );
};

export default Information;
