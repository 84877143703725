import styled from 'styled-components';

export const Svg = styled.svg`
  width: 646px;
  height: auto;

  @media (min-width: 1050px) {
    width: 843px;
  }

  @media (min-width: 1250px) {
    width: 1200px;
  }

  @media (min-width: 1350px) {
    width: 869px;
  }

  @media (min-width: 1480px) {
    width: 1061px;
  }

  @media (min-width: 1670px) {
    width: 1312px;
  }

  .st0 {
    fill: #932a85;
  }
  .st1 {
    fill: #8fc1d4;
  }
  .st2 {
    fill: #ffffff;
  }
  .st3 {
    fill: #efeae9;
  }
  .st4 {
    enable-background: new 0 0 1059.7 647.9;
  }
`;
