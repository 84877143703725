import styled from 'styled-components';
import Image from 'gatsby-image';
import Modal from 'react-modal';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

export const ImageWrapper = styled.div`
  width: 75%;
  height: auto;
  margin: 30px auto;

  @media (min-width: 550px) {
    width: 83%;
    margin: 0 auto;
  }

  @media (min-width: 720px) {
    width: 88%;
  }

  @media (min-width: 850px) {
    width: 80%;
  }

  @media (min-width: 1050px) {
    width: 78%;
  }

  @media (min-width: 1250px) {
    width: 80%;
  }

  @media (min-width: 1480px) {
    width: 85%;
  }

  @media (min-width: 1680px) {
    width: 90%;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 48%;
`;

export const DotsWrapper = styled.div`
  width: 30px;
  height: 120px;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  position: absolute;
  bottom: -30px;
  left: -10px;

  @media (min-width: 1050px) {
    bottom: 0;
    left: 10px;
  }
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  display: none;

  @media (min-width: 850px) {
    display: block;
  }
`;

export const StyledImageMobile = styled(Image)`
  width: 100%;
  height: auto;

  @media (min-width: 850px) {
    display: none;
  }
`;

export const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  transform: scale(${props => (props.isBig ? '1.5' : '1')});
`;

export const StyledButton = styled.div`
  background: transparent;
  width: 24px;
  height: auto;
  margin: 10px;
`;

export const Triangle = styled.button`
  width: 0;
  height: 0;
  border-style: solid;
  background: transparent;
  border-width: ${props =>
    props.isRight ? '12px 0 12px 24px' : '12px 24px 12px 0'};
  border-color: ${props =>
    props.isRight
      ? 'transparent transparent transparent #ffffff'
      : 'transparent #ffffff transparent transparent'};
`;

export const ImageLink = styled.a`
  width: 100%;
  height: auto;
  cursor: pointer;
  display: none;

  @media (min-width: 850px) {
    display: block;
  }
`;

export const ModalImageWrapper = styled.div`
  width: 85%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 1300px) {
    width: 90%;
    height: 90%;
  }

  @media (min-width: 1300px) {
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 1rem;
    }

    ::-webkit-scrollbar-track {
      background: #eeeae8;
      background-clip: content-box;
      border-radius: 15px;
    }

    ::-webkit-scrollbar-thumb {
      background: #922a84;
      border-radius: 15px;
      height: 1rem;
    }
  }
`;

export const StyledModal = styled(Modal)`
  height: calc(100% - 100px);
  width: calc(100% - 100px);
  background-color: ${({ theme }) => theme.colors.blue};
  margin: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ModalButtonWrapper = styled.div`
  width: 97.5%;
  height: 50px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 50%;
  left: 10px;

  @media (min-width: 1300px) {
    left: 20px;
  }
`;

export const ModalStyledButton = styled.div`
  background: transparent;
  width: 24px;
  height: auto;
  margin: 10px;
`;

export const ModalTriangle = styled.button`
  width: 0;
  height: 0;
  border-style: solid;
  background: transparent;
  border-width: ${props =>
    props.isRight ? '12px 0 12px 24px' : '12px 24px 12px 0'};
  border-color: ${props =>
    props.isRight
      ? 'transparent transparent transparent #ffffff'
      : 'transparent #ffffff transparent transparent'};
`;

export const ModalDotsWrapper = styled.div`
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 140px;
  left: 60px;

  @media (min-width: 900px) {
    bottom: 100px;
  }

  @media (min-width: 1000px) {
    bottom: 80px;
    left: 70px;
  }

  @media (min-width: 1080px) {
    bottom: 60px;
    left: 75px;
  }

  @media (min-width: 1160px) {
    bottom: 40px;
    left: 80px;
  }

  @media (min-width: 1200px) {
    bottom: 15px;
    left: 85px;
  }

  @media (min-width: 1300px) {
    width: 30px;
    height: 120px;
    bottom: 45px;
    left: 35px;
    flex-flow: column;
  }
`;

export const ModalCloseButtonWrapper = styled.button`
  border: 0;
  width: 40px;
  height: 40px;
  font-size: ${({ theme }) => theme.font.xxl};
  color: ${({ theme }) => theme.colors.white};
  background-color: transparent;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;
