import React, { useEffect, createRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import gsap from 'gsap';
import FactorySvgMobile from 'components/factorySvgMobile/factorySvgMobile';
import PricingSlider from 'components/pricingSlider/pricingSlider';
import { ScrollScene } from 'scrollscene';
import SquareAnimationNoMargin from 'components/squareAnimationNoMargin/squareAnimationNoMargin';
import {
  SvgWrapper,
  SliderWrapper,
  CatalogWrapper,
  CatalogWrapperImage,
  SquareWrapper,
  TextBox,
  TextWord,
} from './informationMobile.styled';

const InformationMobile = () => {
  const { catalog } = useStaticQuery(graphql`
    query {
      catalog: file(relativePath: { eq: "katalog.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const titleRef = createRef();

  const titleTl = gsap.timeline({ paused: true });

  useEffect(() => {
    titleTl.staggerFrom(
      titleRef.current.children,
      0.45,
      { y: -50, opacity: 0 },
      0.015
    );

    const scrollscene = new ScrollScene({
      triggerElement: titleRef.current,
      reverse: false,
      gsap: {
        timeline: titleTl,
      },
    });
  });

  return (
    <>
      <SvgWrapper>
        <FactorySvgMobile />
      </SvgWrapper>
      <SliderWrapper>
        <PricingSlider />
      </SliderWrapper>
      <CatalogWrapper>
        <CatalogWrapperImage fluid={catalog.sharp.fluid} />
      </CatalogWrapper>
      <SquareWrapper>
        <SquareAnimationNoMargin />
      </SquareWrapper>
      <TextBox ref={titleRef}>
        <TextWord>Pobierz&nbsp;</TextWord>
        <TextWord>wersje&nbsp;</TextWord>
        <TextWord>na&nbsp;</TextWord>
        <TextWord>swój&nbsp;</TextWord>
        <TextWord>komputer:</TextWord>
      </TextBox>
    </>
  );
};

export default InformationMobile;
