import React from 'react';
import InformationDesktop from 'components/informationDesktop/informationDesktop';
import InformationMobile from 'components/informationMobile/informationMobile';
import {
  SectionWrapperDesktop,
  SectionWrapperMobile,
} from './information.styled';

const Information = () => {
  return (
    <>
      <SectionWrapperDesktop>
        <InformationDesktop />
      </SectionWrapperDesktop>
      <SectionWrapperMobile>
        <InformationMobile />
      </SectionWrapperMobile>
    </>
  );
};

export default Information;
