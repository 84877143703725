import React from 'react';

import Layout from 'components/layout/layout';
import Catalog from 'templates/pricing/catalog/catalog';
import Information from 'templates/pricing/information/information';
import FooterMobile from 'components/footerMobile/footerMobile';
import SEO from 'components/SEO/SEO';

const priceList = () => (
  <Layout>
    <SEO title="Cennik" />
    <Information />
    <Catalog />
    <FooterMobile />
  </Layout>
);

export default priceList;
