import React, { useState } from 'react';
import Modal from 'react-modal';
import { useStaticQuery, graphql } from 'gatsby';
import {
  SectionWrapper,
  ImageWrapper,
  ButtonWrapper,
  DotsWrapper,
  StyledImage,
  StyledImageMobile,
  Dot,
  Triangle,
  StyledButton,
  ImageLink,
  StyledModal,
  ModalImageWrapper,
  ModalButtonWrapper,
  ModalStyledButton,
  ModalTriangle,
  ModalDotsWrapper,
  ModalCloseButtonWrapper,
} from './pricingSlider.styled';

const PricingSlider = () => {
  const [index, setIndex] = useState(0);
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        sort: { fields: name, order: ASC }
        filter: { relativeDirectory: { eq: "slider" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(quality: 100, maxWidth: 1628, maxHeight: 1151) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const length = allFile.edges.length - 1;

  const handleNext = () =>
    index === length ? setIndex(0) : setIndex(index + 1);
  const handlePrevious = () =>
    index === 0 ? setIndex(length) : setIndex(index - 1);

  const { node } = allFile.edges[index];

  Modal.setAppElement('#___gatsby');

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    if (window !== undefined) {
      window.location.reload();
    }
  }

  return (
    <SectionWrapper>
      <ImageWrapper>
        <ImageLink onClick={openModal}>
          <StyledImage
            fluid={node.childImageSharp.fluid}
            key={node.id}
            alt={node.name.replace(/-/g, ' ').substring(2)}
          />
        </ImageLink>
        <StyledImageMobile
          fluid={node.childImageSharp.fluid}
          key={node.id}
          alt={node.name.replace(/-/g, ' ').substring(2)}
        />
      </ImageWrapper>
      <ButtonWrapper>
        <StyledButton type="button" onClick={() => handlePrevious()}>
          <Triangle />
        </StyledButton>
        <StyledButton type="button" onClick={() => handleNext()}>
          <Triangle isRight />
        </StyledButton>
      </ButtonWrapper>
      <DotsWrapper>
        <Dot isBig={index === 0} onClick={() => setIndex(0)} />
        <Dot isBig={index === 1} onClick={() => setIndex(1)} />
        <Dot isBig={index === 2} onClick={() => setIndex(2)} />
        <Dot isBig={index === 3} onClick={() => setIndex(3)} />
        <Dot isBig={index === 4} onClick={() => setIndex(4)} />
        <Dot isBig={index === 5} onClick={() => setIndex(5)} />
        <Dot isBig={index === 6} onClick={() => setIndex(6)} />
      </DotsWrapper>
      <StyledModal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <ModalImageWrapper>
          <StyledImage
            fluid={node.childImageSharp.fluid}
            key={node.id}
            alt={node.name.replace(/-g/, '').substring(2)}
          />
        </ModalImageWrapper>
        <ModalButtonWrapper>
          <ModalStyledButton type="button" onClick={() => handlePrevious()}>
            <ModalTriangle />
          </ModalStyledButton>
          <ModalStyledButton type="button" onClick={() => handleNext()}>
            <ModalTriangle isRight />
          </ModalStyledButton>
        </ModalButtonWrapper>
        <ModalDotsWrapper>
          <Dot isBig={index === 0} onClick={() => setIndex(0)} />
          <Dot isBig={index === 1} onClick={() => setIndex(1)} />
          <Dot isBig={index === 2} onClick={() => setIndex(2)} />
          <Dot isBig={index === 3} onClick={() => setIndex(3)} />
          <Dot isBig={index === 4} onClick={() => setIndex(4)} />
          <Dot isBig={index === 5} onClick={() => setIndex(5)} />
          <Dot isBig={index === 6} onClick={() => setIndex(6)} />
        </ModalDotsWrapper>
        <ModalCloseButtonWrapper type="button" onClick={closeModal}>
          &times;
        </ModalCloseButtonWrapper>
      </StyledModal>
    </SectionWrapper>
  );
};

export default PricingSlider;
