import styled from 'styled-components';
import Image from 'gatsby-image';

export const TextWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  width: calc(100% - 50px);
  height: auto;
  margin-left: 50px;
  position: relative;
`;

export const CatalogWrapper = styled.div`
  width: calc(100% - 50px);
  height: auto;
  margin-left: 50px;
  position: relative;
`;

export const SquareWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 30px;
`;

export const SvgWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const SliderWrapper = styled.div`
  width: 450px;
  height: auto;
  position: absolute;
  top: 90px;
  left: 30px;

  @media (min-width: 1050px) {
    width: 600px;
    top: 160px;
  }

  @media (min-width: 1240px) {
    width: 850px;
  }

  @media (min-width: 1350px) {
    width: 600px;
  }

  @media (min-width: 1480px) {
    width: 750px;
  }

  @media (min-width: 1670px) {
    width: 950px;
  }
`;

export const CatalogImageWrapper = styled.div`
  width: 400px;
  height: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  display: none;

  @media (min-width: 1020px) {
    display: block;
  }

  @media (min-width: 1350px) {
    display: none;
  }

  @media (min-width: 1430px) {
    display: block;
    width: 400px;
  }

  @media (min-width: 1850px) {
    width: 550px;
  }
`;

export const CatalogImage = styled(Image)`
  width: 100%;
  height: auto;
`;

export const TextBox = styled.div`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.font.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-top: 30px;
  margin-bottom: 150px;
`;

export const TextWord = styled.span`
  display: inline-block;
`;
