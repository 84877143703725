import React, { createRef, useEffect } from 'react';
import gsap from 'gsap';
import { Svg } from './factorySvg.styled';

const FactorySvg = () => {
  const firstRatchet = createRef();
  const secondRatchet = createRef();
  const thirdRatchet = createRef();
  const fourthRatchet = createRef();
  const fifthRatchet = createRef();
  const sixthRatchet = createRef();

  const firstDot = createRef();
  const secondDot = createRef();
  const thirdDot = createRef();
  const fourthDot = createRef();
  const fifthDot = createRef();
  const sixthDot = createRef();
  const seventhDot = createRef();

  const firstRatchetRotateTl = gsap.timeline({ repeat: -1 });
  const secondRatchetRotateTl = gsap.timeline({ repeat: -1 });
  const thirdRatchetRotateTl = gsap.timeline({ repeat: -1 });
  const fourthRatchetRotateTl = gsap.timeline({ repeat: -1 });
  const fifthRatchetRotateTl = gsap.timeline({ repeat: -1 });
  const sixthRatchetRotateTl = gsap.timeline({ repeat: -1 });

  const firstDotTl = gsap.timeline({ repeat: -1 });
  const secondDotTl = gsap.timeline({ repeat: -1 });
  const thirdDotTl = gsap.timeline({ repeat: -1 });
  const fourthDotTl = gsap.timeline({ repeat: -1 });
  const fifthDotTl = gsap.timeline({ repeat: -1 });
  const sixthDotTl = gsap.timeline({ repeat: -1 });
  const seventhDotTl = gsap.timeline({ repeat: -1 });

  useEffect(() => {
    firstRatchetRotateTl.to(firstRatchet.current, 20, {
      rotation: 360,
      transformOrigin: 'center center',
      ease: 'none',
    });
    secondRatchetRotateTl.to(secondRatchet.current, 10, {
      rotation: 360,
      transformOrigin: 'center center',
      ease: 'none',
    });
    thirdRatchetRotateTl.to(thirdRatchet.current, 10, {
      rotation: 360,
      transformOrigin: 'center center',
      ease: 'none',
    });
    fourthRatchetRotateTl.to(fourthRatchet.current, 60, {
      rotation: 360,
      transformOrigin: 'center center',
      ease: 'none',
    });
    fifthRatchetRotateTl.to(fifthRatchet.current, 60, {
      rotation: 360,
      transformOrigin: 'center center',
      ease: 'none',
    });
    sixthRatchetRotateTl.to(sixthRatchet.current, 10, {
      rotation: 360,
      transformOrigin: 'center center',
      ease: 'none',
    });
    firstDotTl
      .set(firstDot.current, { y: 5 })
      .to(firstDot.current, 8, {
        x: 50,
        y: -35,
        scale: 2,
        transformOrigin: 'center center',
        opacity: 0.7,
        ease: 'none',
      })
      .to(firstDot.current, 8, {
        x: 100,
        y: -60,
        scale: 3,
        transformOrigin: 'center center',
        opacity: 0,
        ease: 'none',
      });
    secondDotTl.set(secondDot.current, { y: 7 }).to(secondDot.current, 15, {
      x: 150,
      y: -50,
      scale: 3.5,
      transformOrigin: 'center center',
      opacity: 0,
      ease: 'none',
    });
    thirdDotTl.set(thirdDot.current, { y: 5 }).to(thirdDot.current, 17, {
      x: -40,
      y: -50,
      scale: 3,
      transformOrigin: 'center center',
      opacity: 0,
      ease: 'none',
    });
    fourthDotTl.set(fourthDot.current, { y: 7 }).to(fourthDot.current, 13, {
      x: 150,
      y: -30,
      scale: 4,
      transformOrigin: 'center center',
      opacity: 0,
      ease: 'none',
    });
    fifthDotTl.set(fifthDot.current, { x: -6 }).to(fifthDot.current, 12, {
      x: 40,
      y: -150,
      scale: 5,
      transformOrigin: 'center center',
      opacity: 0,
      ease: 'none',
    });
    sixthDotTl.set(sixthDot.current, { x: -5 }).to(sixthDot.current, 11, {
      x: 120,
      y: -120,
      scale: 6,
      transformOrigin: 'center center',
      opacity: 0,
      ease: 'none',
    });
    seventhDotTl.set(seventhDot.current, { x: -5 }).to(seventhDot.current, 19, {
      x: 200,
      y: -200,
      scale: 3,
      transformOrigin: 'center center',
      opacity: 0,
      ease: 'none',
    });
  });

  return (
    <Svg
      id="Warstwa_1"
      x="0px"
      y="0px"
      viewBox="0 0 1059.7 647.9"
      className="st4"
    >
      <circle
        id="Dym_1"
        ref={firstDot}
        className="st0"
        cx="481.2"
        cy="82.7"
        r="7.8"
      />
      <circle
        id="Dym_2"
        ref={secondDot}
        className="st0"
        cx="567.1"
        cy="65"
        r="5.5"
      />
      <circle
        id="Dym_3"
        ref={thirdDot}
        className="st0"
        cx="656.6"
        cy="96.2"
        r="7.8"
      />
      <circle
        id="Dym_4"
        ref={fourthDot}
        className="st0"
        cx="758.9"
        cy="75.5"
        r="5.5"
      />
      <circle
        id="Dym_5"
        ref={fifthDot}
        className="st0"
        cx="836.3"
        cy="235.6"
        r="5.5"
      />
      <circle
        id="Dym_6"
        ref={sixthDot}
        className="st0"
        cx="815.8"
        cy="313.2"
        r="6.1"
      />
      <circle
        id="Dym_7"
        ref={seventhDot}
        className="st0"
        cx="820.9"
        cy="384.8"
        r="6.1"
      />
      <rect
        id="SZESCIAN"
        x="11.1"
        y="106.4"
        className="st1"
        width="796.7"
        height="532.1"
      />
      <path
        id="komin_1"
        className="st1"
        d="M494.2,79.5h-24.4c-7.7,0-13.9,6.2-13.9,13.9v34.7c0,7.7,6.2,13.9,13.9,13.9h24.4
	c7.7,0,13.9-6.2,13.9-13.9V93.4C508.1,85.7,501.9,79.5,494.2,79.5z"
      />
      <path
        id="komin_2"
        className="st1"
        d="M591.2,65h-46c-7.7,0-13.9,6.2-13.9,13.9v46c0,7.7,6.2,13.9,13.9,13.9h46
	c7.7,0,13.9-6.2,13.9-13.9v-46C605.1,71.2,598.9,65,591.2,65z"
      />
      <path
        id="komin_3"
        className="st1"
        d="M679.7,93h-46c-7.7,0-13.9,6.2-13.9,13.9v52.2c0,7.7,6.2,13.9,13.9,13.9h46
	c7.7,0,13.9-6.2,13.9-13.9v-52.2C693.6,99.2,687.4,93,679.7,93z"
      />
      <path
        id="komin_4"
        className="st1"
        d="M782.1,75.5h-44.1c-8.2,0-14.8,6.6-14.8,14.8v82.2c0,8.2,6.6,14.8,14.8,14.8h44.1
	c8.2,0,14.8-6.6,14.8-14.8V90.3C796.9,82.1,790.3,75.5,782.1,75.5z"
      />
      <path
        id="komin_5"
        className="st1"
        d="M822.4,196.5h-55.2c-7.7,0-13.9,6.2-13.9,13.9v50.4c0,7.7,6.2,13.9,13.9,13.9h55.2
	c7.7,0,13.9-6.2,13.9-13.9v-50.4C836.3,202.7,830.1,196.5,822.4,196.5z"
      />
      <path
        id="komin_6"
        className="st1"
        d="M803.9,290.7h-26c-7.7,0-13.9,6.2-13.9,13.9v17.1c0,7.7,6.2,13.9,13.9,13.9h26
	c7.7,0,13.9-6.2,13.9-13.9v-17.1C817.8,296.9,811.5,290.7,803.9,290.7z"
      />
      <path
        id="komin_7"
        className="st1"
        d="M808.3,356.1h-43.7c-7.7,0-13.9,6.2-13.9,13.9v32.2c0,7.7,6.2,13.9,13.9,13.9h43.7
	c7.7,0,13.9-6.2,13.9-13.9V370C822.2,362.3,816,356.1,808.3,356.1z"
      />
      <g id="Trybik_1" ref={firstRatchet}>
        <path
          className="st1"
          d="M94.8,160c-17.7,6.5-37.3-2.7-43.8-20.4c-6.5-17.7,2.7-37.3,20.4-43.8c17.7-6.5,37.3,2.7,43.8,20.4
		c1.4,3.9,2.1,7.8,2.1,11.7v0C117.3,141.9,108.7,155,94.8,160 M38.1,126.7c-0.1,4,0.3,8,1.3,11.9l-3.6,1.3l3.1,8.6l3.6-1.3
		c1.9,3.8,4.2,7.3,7,10.4l-2.2,2.4l6.8,6.2l2.2-2.5c2.3,1.6,4.7,3.1,7.3,4.3c3.1,1.4,6.2,2.5,9.5,3.2l-0.4,3.5l9.1,1l0.4-3.5
		c3.9,0,7.8-0.5,11.7-1.5l1.5,4.3l8.6-3.1l-1.6-4.5c3.7-1.8,7-4.2,10-6.9l3.2,2.9l6.2-6.7l-3.3-3.1c1.6-2.2,3-4.6,4.1-7.1
		c0.4-0.9,0.8-1.9,1.2-2.9l4.3,1.1l2.2-8.9l-4.1-1c0.6-3.8,0.7-7.7,0.3-11.5l4.9-1l-1.7-7.8l-0.4-1.1l-4.7,1
		c-0.2-0.6-0.4-1.2-0.6-1.7c-1.2-3.3-2.7-6.4-4.6-9.2l3.2-2.6l-5.9-7l-3.1,2.6c-2.9-2.8-6.1-5.3-9.7-7.3l1.7-4l-8.5-3.5l-1.7,4
		c-2.7-0.9-5.5-1.4-8.4-1.8l-0.1-3.1l-9.2,0.3l0.1,2.7c-3.7,0.4-7.3,1.2-10.9,2.5c-0.3,0.1-0.7,0.3-1,0.4l-1.6-3l0,0l-8.1,4.2
		l1.5,2.9c-3.6,2.4-6.8,5.2-9.6,8.5l-2.2-1.5l-5.2,7.5l2.3,1.6c-0.4,0.7-0.8,1.5-1.1,2.3c-1.3,2.8-2.3,5.6-3,8.5l-3.4-0.3l-0.9,9.1
		L38.1,126.7z"
        />
        <path
          className="st1"
          d="M81.4,122.2l-2,2.3c0,0,0,0,0,0l-1.4,1.7l0.9,2.4c0,0,0,0,0,0c0,0,0,0,0,0l0.9,2.6l1.5,0.3c0,0,0,0,0,0
		l3.7,0.7l0.1-0.2c0,0,0,0,0,0l2.2-2.6c0,0,0,0,0,0l1.1-1.2l-0.7-2c0,0,0,0,0,0l-1.1-3l-1.1-0.2c0,0,0,0,0,0l-4-0.7L81.4,122.2z
		 M84.2,124.8l0.7,0.4l-0.2,0.3c0.2,0.2,0.4,0.5,0.5,0.7l0.4-0.1l0.2,0.8l-0.4,0.1c0,0.3,0,0.6-0.1,0.9l0.4,0.2l-0.4,0.7l-0.3-0.2
		c-0.2,0.2-0.5,0.4-0.7,0.5l0.1,0.4l-0.8,0.2l-0.1-0.4c-0.3,0-0.6,0-0.9-0.1l-0.2,0.3l-0.7-0.4l0.2-0.3c-0.2-0.2-0.4-0.5-0.6-0.8
		l-0.4,0.1l-0.2-0.8l0.4-0.1c0-0.3,0-0.6,0.2-0.9l-0.3-0.2l0.4-0.7l0.3,0.2c0.2-0.2,0.5-0.4,0.8-0.5l-0.1-0.4l0.8-0.2l0.1,0.4
		c0.3,0,0.6,0.1,0.9,0.2L84.2,124.8z"
        />
      </g>
      <g id="Trybik_2" ref={secondRatchet}>
        <path
          className="st1"
          d="M136.7,86.7c-5.7,2.1-12.1-0.9-14.2-6.6c-2.1-5.7,0.9-12.1,6.6-14.2c5.7-2.1,12.1,0.9,14.2,6.6
		c0.5,1.3,0.7,2.5,0.7,3.8v0C144,80.8,141.2,85.1,136.7,86.7 M118.3,75.9c0,1.3,0.1,2.6,0.4,3.9l-1.2,0.4l1,2.8l1.2-0.4
		c0.6,1.2,1.4,2.4,2.3,3.4l-0.7,0.8l2.2,2l0.7-0.8c0.7,0.5,1.5,1,2.4,1.4c1,0.5,2,0.8,3.1,1l-0.1,1.1l3,0.3l0.1-1.1
		c1.3,0,2.5-0.2,3.8-0.5l0.5,1.4l2.8-1l-0.5-1.5c1.2-0.6,2.3-1.3,3.2-2.2l1,0.9l2-2.2l-1.1-1c0.5-0.7,1-1.5,1.3-2.3
		c0.1-0.3,0.3-0.6,0.4-0.9l1.4,0.3l0.7-2.9l-1.3-0.3c0.2-1.2,0.2-2.5,0.1-3.7l1.6-0.3l-0.5-2.5l-0.1-0.4l-1.5,0.3
		c-0.1-0.2-0.1-0.4-0.2-0.6c-0.4-1.1-0.9-2.1-1.5-3l1-0.9l-1.9-2.3l-1,0.8c-0.9-0.9-2-1.7-3.1-2.4l0.5-1.3l-2.7-1.2l-0.5,1.3
		c-0.9-0.3-1.8-0.5-2.7-0.6l0-1l-3,0.1l0,0.9c-1.2,0.1-2.4,0.4-3.5,0.8c-0.1,0-0.2,0.1-0.3,0.1l-0.5-1l0,0l-2.6,1.4l0.5,1
		c-1.2,0.8-2.2,1.7-3.1,2.8l-0.7-0.5l-1.7,2.4l0.7,0.5c-0.1,0.2-0.3,0.5-0.4,0.7c-0.4,0.9-0.7,1.8-1,2.8l-1.1-0.1l-0.3,3L118.3,75.9
		z"
        />
        <path
          className="st1"
          d="M132.4,74.4l-0.6,0.8c0,0,0,0,0,0l-0.5,0.5l0.3,0.8c0,0,0,0,0,0c0,0,0,0,0,0l0.3,0.8l0.5,0.1c0,0,0,0,0,0
		l1.2,0.2l0-0.1c0,0,0,0,0,0l0.7-0.9c0,0,0,0,0,0l0.3-0.4l-0.2-0.7c0,0,0,0,0,0l-0.3-1l-0.4-0.1c0,0,0,0,0,0L132.4,74.4L132.4,74.4z
		 M133.3,75.3l0.2,0.1l-0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2l0.1,0l0.1,0.3l-0.1,0c0,0.1,0,0.2,0,0.3l0.1,0.1l-0.1,0.2l-0.1-0.1
		c-0.1,0.1-0.1,0.1-0.2,0.2l0,0.1l-0.3,0.1l0-0.1c-0.1,0-0.2,0-0.3,0l-0.1,0.1l-0.2-0.1l0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3l-0.1,0
		l-0.1-0.3l0.1,0c0-0.1,0-0.2,0.1-0.3l-0.1-0.1l0.1-0.2l0.1,0.1c0.1-0.1,0.2-0.1,0.3-0.2l0-0.1l0.3-0.1l0,0.1c0.1,0,0.2,0,0.3,0.1
		L133.3,75.3z"
        />
      </g>
      <g id="Trybik_3" ref={thirdRatchet}>
        <path
          className="st1"
          d="M169.7,130.4c-9,3.3-18.9-1.4-22.1-10.3c-3.3-9,1.4-18.9,10.3-22.1c9-3.3,18.9,1.4,22.1,10.3
		c0.7,2,1,3.9,1,5.9v0C181.1,121.2,176.7,127.9,169.7,130.4 M141.1,113.6c-0.1,2,0.2,4,0.7,6l-1.8,0.7l1.6,4.4l1.8-0.7
		c0.9,1.9,2.1,3.7,3.6,5.3l-1.1,1.2l3.4,3.1l1.1-1.2c1.1,0.8,2.4,1.6,3.7,2.2c1.6,0.7,3.2,1.3,4.8,1.6l-0.2,1.8l4.6,0.5l0.2-1.8
		c2,0,4-0.3,5.9-0.8l0.8,2.2l4.4-1.6l-0.8-2.3c1.8-0.9,3.5-2.1,5-3.5l1.6,1.5l3.1-3.4l-1.7-1.5c0.8-1.1,1.5-2.3,2.1-3.6
		c0.2-0.5,0.4-1,0.6-1.5l2.2,0.5l1.1-4.5l-2.1-0.5c0.3-1.9,0.4-3.9,0.2-5.8l2.5-0.5l-0.8-4l-0.2-0.5l-2.4,0.5
		c-0.1-0.3-0.2-0.6-0.3-0.9c-0.6-1.7-1.4-3.2-2.3-4.7l1.6-1.3l-3-3.6l-1.6,1.3c-1.4-1.4-3.1-2.7-4.9-3.7l0.9-2l-4.3-1.8l-0.8,2
		c-1.4-0.4-2.8-0.7-4.2-0.9l0-1.5l-4.6,0.1l0,1.4c-1.9,0.2-3.7,0.6-5.5,1.3c-0.2,0.1-0.3,0.1-0.5,0.2l-0.8-1.5l0,0l-4.1,2.1l0.8,1.5
		c-1.8,1.2-3.5,2.6-4.9,4.3l-1.1-0.8l-2.6,3.8l1.2,0.8c-0.2,0.4-0.4,0.8-0.6,1.1c-0.7,1.4-1.2,2.8-1.5,4.3l-1.7-0.2l-0.5,4.6
		L141.1,113.6z"
        />
        <path
          className="st1"
          d="M162.9,111.3l-1,1.2c0,0,0,0,0,0l-0.7,0.8l0.4,1.2c0,0,0,0,0,0c0,0,0,0,0,0l0.5,1.3l0.7,0.1c0,0,0,0,0,0
		l1.9,0.3l0.1-0.1c0,0,0,0,0,0l1.1-1.3c0,0,0,0,0,0l0.5-0.6l-0.4-1c0,0,0,0,0,0l-0.5-1.5l-0.6-0.1c0,0,0,0,0,0L162.9,111.3
		L162.9,111.3z M164.3,112.6l0.4,0.2l-0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.4l0.2,0l0.1,0.4l-0.2,0c0,0.2,0,0.3-0.1,0.5l0.2,0.1l-0.2,0.4
		l-0.2-0.1c-0.1,0.1-0.2,0.2-0.4,0.3l0,0.2l-0.4,0.1l0-0.2c-0.2,0-0.3,0-0.5-0.1l-0.1,0.2l-0.4-0.2l0.1-0.2
		c-0.1-0.1-0.2-0.2-0.3-0.4l-0.2,0l-0.1-0.4l0.2,0c0-0.2,0-0.3,0.1-0.5l-0.2-0.1l0.2-0.4l0.2,0.1c0.1-0.1,0.2-0.2,0.4-0.3l0-0.2
		l0.4-0.1l0,0.2c0.2,0,0.3,0,0.5,0.1L164.3,112.6z"
        />
      </g>
      <g id="Trybik_4" ref={fourthRatchet}>
        <path
          className="st1"
          d="M308.7,170.2c-27.2,9.9-57.3-4.1-67.2-31.3c-9.9-27.2,4.1-57.3,31.3-67.2c27.2-9.9,57.3,4.1,67.2,31.3
		c2.2,5.9,3.2,12,3.2,17.9v0C343.2,142.3,330,162.4,308.7,170.2 M221.7,119.1c-0.2,6.1,0.5,12.3,2.1,18.3l-5.5,2l4.8,13.2l5.5-2
		c2.8,5.9,6.5,11.2,10.8,16l-3.3,3.7l10.4,9.5l3.4-3.8c3.5,2.5,7.2,4.7,11.2,6.6c4.7,2.2,9.6,3.8,14.5,4.9l-0.6,5.4l14,1.5l0.6-5.4
		c6,0,12-0.8,17.9-2.4l2.3,6.6l13.2-4.7l-2.5-6.9c5.6-2.8,10.7-6.4,15.3-10.6l4.9,4.5l9.5-10.3l-5.1-4.7c2.4-3.4,4.5-7,6.3-10.9
		c0.7-1.5,1.3-2.9,1.9-4.4l6.7,1.6l3.4-13.7l-6.4-1.6c0.9-5.8,1.1-11.8,0.5-17.7l7.5-1.6l-2.6-12l-0.6-1.7l-7.2,1.5
		c-0.3-0.9-0.6-1.8-0.9-2.6c-1.8-5-4.2-9.8-7.1-14.1l4.9-4.1l-9-10.8l-4.8,4c-4.4-4.3-9.4-8.1-14.8-11.1l2.6-6.2l-13-5.4l-2.5,6.1
		c-4.2-1.3-8.5-2.2-12.8-2.7l-0.1-4.7l-14.1,0.4l0.1,4.2c-5.6,0.6-11.2,1.8-16.7,3.8c-0.5,0.2-1,0.4-1.6,0.6l-2.4-4.6l0,0l-12.5,6.5
		l2.4,4.5c-5.5,3.6-10.5,8-14.7,13.1l-3.4-2.3l-8,11.6l3.5,2.4c-0.6,1.1-1.2,2.3-1.7,3.5c-2,4.3-3.5,8.6-4.6,13.1l-5.2-0.5l-1.4,14
		L221.7,119.1z"
        />
        <path
          className="st1"
          d="M288,112.2l-3.1,3.6c0,0,0,0,0,0l-2.2,2.6l1.3,3.7c0,0,0,0,0.1-0.1c0,0,0,0-0.1,0.1l1.4,4l2.3,0.4c0,0,0,0,0,0
		l5.8,1.1l0.2-0.3c0,0,0,0,0,0l3.4-4c0,0,0,0,0,0l1.6-1.9l-1.1-3.1c0,0,0,0,0,0l-1.6-4.5l-1.7-0.3c0,0,0,0,0,0l-6.1-1.1L288,112.2z
		 M292.4,116.2l1.1,0.7l-0.3,0.5c0.3,0.3,0.6,0.7,0.8,1.1l0.6-0.1l0.3,1.2l-0.6,0.1c0,0.5-0.1,1-0.2,1.4l0.5,0.3l-0.7,1.1l-0.5-0.3
		c-0.3,0.3-0.7,0.6-1.1,0.8l0.1,0.6l-1.2,0.3l-0.1-0.6c-0.5,0-1,0-1.4-0.2l-0.3,0.5l-1.1-0.7l0.3-0.5c-0.4-0.3-0.7-0.7-0.9-1.2
		l-0.6,0.1l-0.3-1.2l0.6-0.1c0-0.5,0.1-1,0.3-1.4l-0.5-0.3l0.7-1.1l0.5,0.3c0.3-0.4,0.7-0.6,1.2-0.8l-0.1-0.6l1.2-0.3l0.1,0.6
		c0.5,0,1,0.1,1.4,0.3L292.4,116.2z"
        />
      </g>
      <g id="Trybik_5" ref={fifthRatchet}>
        <path
          className="st1"
          d="M802.4,558.4c-27.2,9.9-57.3-4.1-67.2-31.3c-9.9-27.2,4.1-57.3,31.3-67.2c27.2-9.9,57.3,4.1,67.2,31.3
		c2.2,5.9,3.2,12,3.2,17.9v0C836.9,530.6,823.7,550.7,802.4,558.4 M715.4,507.3c-0.2,6.1,0.5,12.3,2.1,18.3l-5.5,2l4.8,13.2l5.5-2
		c2.8,5.9,6.5,11.2,10.8,16l-3.3,3.7l10.4,9.5l3.4-3.8c3.5,2.5,7.2,4.7,11.2,6.6c4.7,2.2,9.6,3.8,14.5,4.9l-0.6,5.4l14,1.5l0.6-5.4
		c6,0,12-0.8,17.9-2.4l2.3,6.6l13.2-4.7l-2.5-6.9c5.6-2.8,10.7-6.4,15.3-10.6l4.9,4.5l9.5-10.3l-5.1-4.7c2.4-3.4,4.5-7,6.3-10.9
		c0.7-1.5,1.3-2.9,1.9-4.4l6.7,1.6l3.4-13.7l-6.4-1.6c0.9-5.8,1.1-11.8,0.5-17.7l7.5-1.6l-2.6-12l-0.6-1.7l-7.2,1.5
		c-0.3-0.9-0.6-1.8-0.9-2.6c-1.8-5-4.2-9.8-7.1-14.1l4.9-4.1l-9-10.8l-4.8,4c-4.4-4.3-9.4-8.1-14.8-11.1l2.6-6.2l-13-5.4l-2.5,6.1
		c-4.2-1.3-8.5-2.2-12.8-2.7l-0.1-4.7l-14.1,0.4l0.1,4.2c-5.6,0.6-11.2,1.8-16.7,3.8c-0.5,0.2-1,0.4-1.6,0.6l-2.4-4.6l0,0l-12.5,6.5
		l2.4,4.5c-5.5,3.6-10.5,8-14.7,13.1l-3.4-2.3l-8,11.6l3.5,2.4c-0.6,1.1-1.2,2.3-1.7,3.5c-2,4.3-3.5,8.6-4.6,13.1l-5.2-0.5l-1.4,14
		L715.4,507.3z"
        />
        <path
          className="st1"
          d="M781.7,500.4l-3.1,3.6c0,0,0,0,0,0l-2.2,2.6l1.3,3.7c0,0,0,0,0.1-0.1c0,0,0,0-0.1,0.1l1.4,4l2.3,0.4
		c0,0,0,0,0,0l5.8,1.1l0.2-0.3c0,0,0,0,0,0l3.4-4c0,0,0,0,0,0l1.6-1.9l-1.1-3.1c0,0,0,0,0,0l-1.6-4.5l-1.7-0.3c0,0,0,0,0,0l-6.1-1.1
		L781.7,500.4z M786.1,504.4l1.1,0.7l-0.3,0.5c0.3,0.3,0.6,0.7,0.8,1.1l0.6-0.1l0.3,1.2l-0.6,0.1c0,0.5-0.1,1-0.2,1.4l0.5,0.3
		l-0.7,1.1l-0.5-0.3c-0.3,0.3-0.7,0.6-1.1,0.8l0.1,0.6l-1.2,0.3l-0.1-0.6c-0.5,0-1,0-1.4-0.2l-0.3,0.5l-1.1-0.7l0.3-0.5
		c-0.4-0.3-0.7-0.7-0.9-1.2l-0.6,0.1l-0.3-1.2l0.6-0.1c0-0.5,0.1-1,0.3-1.4l-0.5-0.3l0.7-1.1l0.5,0.3c0.3-0.4,0.7-0.6,1.2-0.8
		l-0.1-0.6l1.2-0.3l0.1,0.6c0.5,0,1,0.1,1.4,0.3L786.1,504.4z"
        />
      </g>
      <g id="Trybik_6" ref={sixthRatchet}>
        <path
          className="st1"
          d="M870.8,598.6c-9,3.3-18.9-1.4-22.1-10.3c-3.3-9,1.4-18.9,10.3-22.1c9-3.3,18.9,1.4,22.1,10.3
		c0.7,2,1,3.9,1,5.9v0C882.1,589.4,877.8,596.1,870.8,598.6 M842.1,581.8c-0.1,2,0.2,4,0.7,6l-1.8,0.7l1.6,4.4l1.8-0.7
		c0.9,1.9,2.1,3.7,3.6,5.3l-1.1,1.2l3.4,3.1l1.1-1.2c1.1,0.8,2.4,1.6,3.7,2.2c1.6,0.7,3.2,1.3,4.8,1.6l-0.2,1.8l4.6,0.5l0.2-1.8
		c2,0,4-0.3,5.9-0.8l0.8,2.2l4.4-1.6l-0.8-2.3c1.8-0.9,3.5-2.1,5-3.5l1.6,1.5l3.1-3.4l-1.7-1.5c0.8-1.1,1.5-2.3,2.1-3.6
		c0.2-0.5,0.4-1,0.6-1.5l2.2,0.5l1.1-4.5l-2.1-0.5c0.3-1.9,0.4-3.9,0.2-5.8l2.5-0.5l-0.8-4l-0.2-0.5l-2.4,0.5
		c-0.1-0.3-0.2-0.6-0.3-0.9c-0.6-1.7-1.4-3.2-2.3-4.7l1.6-1.3l-3-3.6l-1.6,1.3c-1.4-1.4-3.1-2.7-4.9-3.7l0.9-2l-4.3-1.8l-0.8,2
		c-1.4-0.4-2.8-0.7-4.2-0.9l0-1.5l-4.6,0.1l0,1.4c-1.9,0.2-3.7,0.6-5.5,1.3c-0.2,0.1-0.3,0.1-0.5,0.2l-0.8-1.5l0,0l-4.1,2.1l0.8,1.5
		c-1.8,1.2-3.5,2.6-4.9,4.3l-1.1-0.8l-2.6,3.8l1.2,0.8c-0.2,0.4-0.4,0.8-0.6,1.1c-0.7,1.4-1.2,2.8-1.5,4.3L841,577l-0.5,4.6
		L842.1,581.8z"
        />
        <path
          className="st1"
          d="M863.9,579.5l-1,1.2c0,0,0,0,0,0l-0.7,0.8l0.4,1.2c0,0,0,0,0,0c0,0,0,0,0,0l0.5,1.3l0.7,0.1c0,0,0,0,0,0
		l1.9,0.3l0.1-0.1c0,0,0,0,0,0l1.1-1.3c0,0,0,0,0,0l0.5-0.6l-0.4-1c0,0,0,0,0,0l-0.5-1.5l-0.6-0.1c0,0,0,0,0,0L863.9,579.5
		L863.9,579.5z M865.4,580.8l0.4,0.2l-0.1,0.2c0.1,0.1,0.2,0.2,0.3,0.4l0.2,0l0.1,0.4l-0.2,0c0,0.2,0,0.3-0.1,0.5l0.2,0.1l-0.2,0.4
		l-0.2-0.1c-0.1,0.1-0.2,0.2-0.4,0.3l0,0.2l-0.4,0.1l0-0.2c-0.2,0-0.3,0-0.5-0.1l-0.1,0.2l-0.4-0.2l0.1-0.2
		c-0.1-0.1-0.2-0.2-0.3-0.4l-0.2,0l-0.1-0.4l0.2,0c0-0.2,0-0.3,0.1-0.5l-0.2-0.1l0.2-0.4l0.2,0.1c0.1-0.1,0.2-0.2,0.4-0.3l0-0.2
		l0.4-0.1l0,0.2c0.2,0,0.3,0,0.5,0.1L865.4,580.8z"
        />
      </g>
    </Svg>
  );
};

export default FactorySvg;
